import './stock-vs-dose-report-datatables.css';
import gql from 'graphql-tag';
import { GraphQLBaseViewModel } from '$pages/common';
import { customElement, bindable, autoinject } from 'aurelia-framework';
import { IGridColumn } from '$components/grid/grid';
import {
  StockVsDoseReportDataQuery,
  StockVsDoseReportDataQuery_reports_stockVsDose_data_doseCalibration,
  StockVsDoseReportDataQuery_reports_stockVsDose_data_online,
  StockVsDoseReportDataQuery_reports_stockVsDose_data_offline,
  StockVsDoseReportDownload,
  StockVsDoseReportDataQueryVariables,
  StockVsDoseReportDownloadVariables
} from '$typings/graphql';
import { orderByPredicate } from '$lib/sorting';
import { BlobService } from '../../../../services';
import { getLogger } from 'aurelia-logging';
import { IFetchOptions } from '$pages/common/GraphQLFetcher';

@customElement('stock-vs-dose-report-datatables')
@autoinject()
export class StockVsDoseReportDatatables extends GraphQLBaseViewModel<
  void,
  StockVsDoseReportDataQuery,
  StockVsDoseReportDataQueryVariables
> {
  constructor(private blobService: BlobService) {
    super(getLogger(StockVsDoseReportDatatables.name));
  }
  @bindable() class: string;
  @bindable() variablesForData: undefined | StockVsDoseReportDataQueryVariables;

  fetchOptions: IFetchOptions = {
    revalidateOnEvents: false
  }

  variablesForDataChanged() {
    this.variables = this.variablesForData;
  }

  query = gql`
    query StockVsDoseReportDataQuery($input: ScheduledReportFilter!) {
      reports {
        stockVsDose(input: $input) {
          data {
            siteCount
            doseCalibration {
              accumulatedDose
              alarmDuration
              alias
              deviation
              tankDrop
            }
            offline {
              alias
              offlineDuration
            }
            online {
              accumulatedDose
              alias
              deviation
              tankDrop
            }
            seriousDoseCalibration {
              accumulatedDose
              alarmDuration
              alias
              deviation
              tankDrop
            }
          }
        }
      }
    }
  `;

  showInteractiveReport = (variables: StockVsDoseReportDataQueryVariables) =>
    this.runQuery(this.query, variables);

  downloadExcel = async (
    variables: StockVsDoseReportDownloadVariables
  ) => {
    const query = gql`
      query StockVsDoseReportDownload($input: ScheduledReportFilter!) {
        reports {
          stockVsDose(input: $input) {
            blobUrl
          }
        }
      }
    `;
    try {

      const result = await this.runQuery<StockVsDoseReportDownload, StockVsDoseReportDownloadVariables>(query, variables);
      const { stockVsDose } = result.reports;
      
      if(!stockVsDose)
        throw new Error('Could not create stockvsdose report');
      
      const { blobUrl } = stockVsDose;
      await this.blobService.downloadFileFromUrl(
        blobUrl,
        'StockVsDoseReport.xlsx'
      );  
    } catch(error) {
      throw Error('Could not create stockvsdose report');
    }
    
  };

  doseCalibrationColumns: IGridColumn<
    StockVsDoseReportDataQuery_reports_stockVsDose_data_doseCalibration
  >[] = [
    {
      columnTitle: 'UI_Reports_SitesSummary_Table_Headers_Dose_SiteAlias',
      property: 'alias',
      sort: (list, order) => orderByPredicate(list, o => o.alias, order),
      type: 'string',
      sortedByInitially: 'asc'
    },
    {
      columnTitle: 'UI_Reports_SitesSummary_Table_Headers_Dose_TankDrop',
      property: 'tankDrop',
      sort: (list, order) => orderByPredicate(list, o => o.tankDrop, order),
      type: 'integer'
    },
    {
      columnTitle: 'UI_Reports_SitesSummary_Table_Headers_Dose_AccumulatedDose',
      property: 'accumulatedDose',
      sort: (list, order) =>
        orderByPredicate(list, o => o.accumulatedDose, order),
      type: 'integer'
    },
    {
      columnTitle:
        'UI_Reports_SitesSummary_Table_Headers_Dose_PercentDeviation',
      property: 'deviation',
      sort: (list, order) => orderByPredicate(list, o => o.deviation, order),
      type: 'integer'
    },
    {
      columnTitle: 'UI_Reports_SitesSummary_Table_Headers_Dose_AlarmDuration',
      property: 'alarmDuration',
      sort: (list, order) =>
        orderByPredicate(list, o => o.alarmDuration, order),
      type: 'duration'
    }
  ];

  onlineSitesColumns: IGridColumn<
    StockVsDoseReportDataQuery_reports_stockVsDose_data_online
  >[] = [
    {
      columnTitle: 'UI_Reports_SitesSummary_Table_Headers_Online_SiteAlias',
      property: 'alias',
      sort: (list, order) => orderByPredicate(list, o => o.alias, order),
      type: 'string',
      sortedByInitially: 'asc'
    },
    {
      columnTitle: 'UI_Reports_SitesSummary_Table_Headers_Online_TankDrop',
      property: 'tankDrop',
      sort: (list, order) => orderByPredicate(list, o => o.tankDrop, order),
      type: 'integer'
    },
    {
      columnTitle:
        'UI_Reports_SitesSummary_Table_Headers_Online_AccumulatedDose',
      property: 'accumulatedDose',
      sort: (list, order) =>
        orderByPredicate(list, o => o.accumulatedDose, order),
      type: 'integer'
    },
    {
      columnTitle:
        'UI_Reports_SitesSummary_Table_Headers_Online_PercentDeviation',
      property: 'deviation',
      sort: (list, order) => orderByPredicate(list, o => o.deviation, order),
      type: 'integer'
    }
  ];

  offlineSitesColumns: IGridColumn<
    StockVsDoseReportDataQuery_reports_stockVsDose_data_offline
  >[] = [
    {
      columnTitle: 'UI_Reports_SitesSummary_Table_Headers_Offline_SiteAlias',
      property: 'alias',
      sort: (list, order) => orderByPredicate(list, o => o.alias, order),
      type: 'string',
      sortedByInitially: 'asc'
    },
    {
      columnTitle: 'UI_Reports_SitesSummary_Table_Headers_Offline_Duration',
      property: 'offlineDuration',
      sort: (list, order) =>
        orderByPredicate(list, o => o.offlineDuration, order),
      type: 'duration'
    }
  ];
}
