import './stock-vs-dose-report.css';
import { autoinject } from 'aurelia-framework';
import { bindable } from 'aurelia-templating';
import {
  Reports,
} from '$typings/graphql-codegen';
import ScheduledReportFilterReact from '../modules/schedule-report-filters/scheduled-report-filter.react';
import React from 'react';
import { createRoot, Root } from 'react-dom/client';
import { ScheduledReportFilter, StockVsDoseReportDataQueryVariables } from '$typings/graphql';

interface IStockVsDoseReportArguments {
  scheduledReportId?: string;
}

@autoinject()
export class StockVsDoseReport {
  @bindable() scheduledReportId: string | undefined;
  react: HTMLElement | null;
  parent: HTMLElement | null;
  reactRoot: Root | null;

  report = Reports.StockVsDose;
  variablesForData: StockVsDoseReportDataQueryVariables

  activate(params: IStockVsDoseReportArguments) {
    this.scheduledReportId = params.scheduledReportId;
  }

  attached() {
    if (!this.react) return;
    this.parent = this.react.parentElement;
    if (!this.parent) return;
    const element = React.createElement(ScheduledReportFilterReact, {
      report: this.report, 
      scheduledReportId: this.scheduledReportId, 
      filtersChanged: this.setFilters,
    });
    this.reactRoot = createRoot(this.parent)
    this.reactRoot.render(element);
    }

  detached() {
    if (this.parent) {
      this.reactRoot?.unmount()
    }
  }

  setFilters = (filter: ScheduledReportFilter) => {
    this.variablesForData = { input: filter };
  };
}
